import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import AssesmentDescriptionSection, {
  AssesmentDescriptionSectionType,
} from 'components/organisms/assesment-description-section';
import CaseStudies, { CaseStudiesType } from 'components/organisms/case-studies';
import CompanyPdfSections from 'components/organisms/company-pdf-section';
import HalfBannerDescription, {
  HalfBannerDescriptionType,
} from 'components/organisms/half-banner-description';
import ImageBulletSection from 'components/organisms/image-bullet-section';
import PostsSectionRowSlider, {
  PostsSectionRowSliderType,
} from 'components/organisms/posts-section-row-slider';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import TextBannerCardSoftware from 'components/organisms/text-banner-card-software';
import YtMovieSection from 'components/organisms/yt-movie-section';
import BannerCard from 'components/molecules/banner-card';
import IndustriesBanner, { IndustriesBannerType } from 'components/molecules/industries-banner';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import { WpImageType } from 'components/atoms/wp-image';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
  offersPayrollSoftware: {
    template: {
      offersPayrollSoftwareData: {
        bannerQuaternary: IndustriesBannerType;
        payrollSoftwareDescription: AssesmentDescriptionSectionType;
        joinToHrk: HalfBannerDescriptionType;
        payrollCaseStudies: CaseStudiesType;
        knowHowArticles: PostsSectionRowSliderType;
        advantagesSection: TextBannerCardType;
        payrollSoftwareSecurity: {
          securityHeading: string;
          securityImage: WpImageType;
          securityText: string;
          bulletsList: [];
        };
        systemDemo: {
          naglowek: string;
          download: {
            meetingsHeading: string;
            subTitle: string;
            documentsList: any;
          };
          video: {
            placeholderText: string;
            youtubeLink: string;
            placeholderImage: WpImageType;
          };
        };
        otherServicesBlock: TextBannerCardType;
      };
    };
  };
};

export const OffersPayrollSoftwarePage = ({
  pageContext,
  data: {
    offersPayrollSoftware: {
      template: {
        offersPayrollSoftwareData: {
          bannerQuaternary,
          payrollSoftwareDescription,
          joinToHrk,
          payrollCaseStudies,
          knowHowArticles,
          advantagesSection,
          payrollSoftwareSecurity: { securityHeading, securityImage, securityText, bulletsList },
          systemDemo: {
            naglowek,
            download: { meetingsHeading, subTitle, documentsList },
            video: { placeholderText, youtubeLink, placeholderImage },
          },
          otherServicesBlock,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <IndustriesBanner {...bannerQuaternary} pageContext={pageContext} isWhite />
    <AssesmentDescriptionSection {...payrollSoftwareDescription} />
    <TextBannerCardSoftware isVisible {...advantagesSection}>
      <div style={{ marginBottom: '-144px' }}>
        <div style={{ display: 'inline-flex' }}>
          {advantagesSection.cardList
            ? advantagesSection.cardList
                .slice(0, 4)
                .map((item) => (
                  <BannerCard
                    {...item}
                    key={item.text}
                    padding={theme.gap.md}
                    mobilePadding={theme.gap.sm}
                  />
                ))
            : null}
        </div>
        <div style={{ display: 'inline-flex' }}>
          {advantagesSection.cardList
            ? advantagesSection.cardList
                .slice(0, 4)
                .map((item) => (
                  <BannerCard
                    {...item}
                    key={item.text}
                    padding={theme.gap.md}
                    mobilePadding={theme.gap.sm}
                  />
                ))
            : null}
        </div>
      </div>
    </TextBannerCardSoftware>
    <TextBannerCardSoftware {...advantagesSection}>
      {advantagesSection.cardList
        ? advantagesSection.cardList.map((item) => (
            <BannerCard
              {...item}
              key={item.text}
              padding={theme.gap.md}
              mobilePadding={theme.gap.sm}
            />
          ))
        : null}
    </TextBannerCardSoftware>
    <ImageBulletSection
      bulletList={bulletsList}
      titleTop={securityHeading}
      showDots
      title={securityText}
      image={securityImage}
    />
    <YtMovieSection
      placeholderImage={placeholderImage}
      placeholderText={placeholderText}
      naglowek={naglowek}
      youtubeLink={youtubeLink}
    />
    <CompanyPdfSections
      isWhite
      heading={meetingsHeading}
      subHeading={subTitle}
      {...{ documentsList }}
    />
    <CaseStudies {...payrollCaseStudies} />
    <PostsSectionRowSlider
      {...knowHowArticles}
      bgColor={theme.colors.gray0}
      margin={`${theme.gap.xl} 0 0 0`}
      mobileMargin={`${theme.gap.md} 0 0 0`}
    />
    <LetsTalkBanner {...letsTalkBanner} margin={`0 auto ${theme.gap.xl}`} />
    <TextBannerCardSection {...otherServicesBlock} textColumn>
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => <BannerCard {...item} key={item.text} />)
        : null}
    </TextBannerCardSection>
    <HalfBannerDescription {...joinToHrk} />
  </Layout>
);

export const query = graphql`
  query OffersPayrollSoftware($id: String!, $commonComponentsSlug: String!) {
    offersPayrollSoftware: wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_OffersPayrollSoftware {
          offersPayrollSoftwareData {
            advantagesSection {
              heading
              subTitle
              text
              cardList: advantagesList {
                heading
                icon {
                  name
                }
              }
            }
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            joinToHrk {
              bannerHeading: heading
              bannerSubHeading: subTitle
              bannerText: text
              bannerLinkButton: linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    title
                    url
                  }
                }
              }
              bannerImage: image {
                ...WpImageFragment
              }
            }
            knowHowArticles {
              heading
              subTitle
              text
              posts: choosenPosts {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  categories {
                    nodes {
                      name
                    }
                  }
                  tags {
                    nodes {
                      name
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            payrollCaseStudies {
              subTitle
              heading
              imageCaseStudy {
                ...WpImageFragment
              }
              caseStudyPost {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  knowHowPostData {
                    postTemplate {
                      ... on WpKnow_how_Knowhowpostdata_PostTemplate_Author {
                        firstName
                        lastName
                        position
                      }
                    }
                  }
                  categories {
                    nodes {
                      name
                    }
                  }
                  tags {
                    nodes {
                      name
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            payrollSoftwareDescription {
              advantagesList {
                listText: text
                icon {
                  name
                }
              }
              heading
              headingAdvantages
              text
              textAdvantages
            }
            payrollSoftwareSecurity {
              securityHeading: heading
              bulletsList {
                text
              }
              securityImage: image {
                ...WpImageFragment
              }
              securityText: text
            }
            systemDemo {
              naglowek
              download {
                meetingsHeading: heading
                subTitle
                documentsList: downloadList {
                  downloadLabel: buttonLabel
                  file {
                    sourceUrl
                    filename
                  }
                }
              }
              video {
                placeholderText
                youtubeLink
                placeholderImage {
                  ...WpImageFragment
                }
              }
            }
            otherServicesBlock {
              subTitle
              heading
              text
              cardList: servicesList {
                heading
                text
                icon {
                  name
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersPayrollSoftwarePage;
